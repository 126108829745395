import { getEnvMapID } from '@/utils/getEnvMapApi';

export const mapOptions = {
  center: { lat: 38.54580751879986, lng: -100.722244 },
  zoom: 5,
  zoomControlOptions: {
    position: 6
  },
  streetViewControl: false,
  fullscreenControl: false,
  keyboardShortcuts: false,
  mapId: getEnvMapID()
}

export const polyOptions = <T>( map: google.maps.Map, polyPath: T) => {
  return {
    map: map,
    fillColor: '#000',
    fillOpacity: 0.03, //0.03
    strokeColor: '#000',
    strokeWeight: 1.3,
    clickable: false,
    zIndex: 1,
    paths: polyPath,
    editable: false,
  }
};

export const mapDataOptions = {
  fillColor: '#c4c4c4',
  fillOpacity: 0.05,
  strokeColor: '#c4c4c4',
  strokeWeight: 1.5,
  clickable: false,
  zIndex: 0,
}