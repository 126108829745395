import { ref } from 'vue';
import { defineStore } from 'pinia';
import api from '@/lib/axios'
import { useRoute } from 'vue-router';
import type { CampaignsResponseData } from '@/types/api-response/campaigns'
import { useFilters } from '@/composables/useFilters';
import { useAdvancedFilters } from '@/composables/useAdvancedFilters';
import { useMapHelpers } from '@/composables/google-map/useMapHelpers';
import { LOADING, RESPONSE } from '@/types/enums'
import { processing } from '@/composables/useLoadingState';
import { useCampaignStore } from './campaign';
import { LotHuntScrub } from '@/modules/map/lothuntScrub';

export const useSaveCampaign = defineStore('save-campaign', () => {
  const filter = useFilters()
  const advancedFilter = useAdvancedFilters()
  const { selectedPolygon } = useMapHelpers()

  const lotScrub = new LotHuntScrub()
  const campaign = useCampaignStore()

  const lastSavedTimeStamp = ref<Date | null>(null)
  const isSavingCampaign = ref<boolean>(false)

 /**
  * Prevent saving the campaign upon fetching
  * the campaign data.
  */
  const skipSaveCampaign = ref<boolean>(true)
  const preventInitialFetch = ref<boolean>(true)

  const route = useRoute()

  const getCampaignId = () => {
    const locationId = window.location.pathname?.split('/')

    if (locationId) return locationId[locationId.length - 1]
    return route.params.campaignId
  }

  /**
   * Resets the last saved timestamp and
   * set the preventInitialSave to false to
   * prevent fetching when filters are set.
   */
  const resetAutoSave = () => {
    skipSaveCampaign.value = true
    lastSavedTimeStamp.value = null 
    preventInitialFetch.value = true
  }

  //eslint-disable-next-line complexity
  const saveCampaign = async (
    skipPreventFetch?: boolean,
    loadingId?: string,
  ): Promise<boolean | undefined> => {
    /**
     * Fetching the campaign will set the
     * values of the filters which will then 
     * trigger the autosave.
    */
    if (skipSaveCampaign.value && !skipPreventFetch) {
      skipSaveCampaign.value = false 
      return
    }

    processing.value = LOADING.SAVE_CAMPAIGN + (loadingId ? loadingId : '')
    isSavingCampaign.value = true 

    const { 
      min_price: min_estimated_price, min_acres, county_id,
      max_price: max_estimated_price, max_acres, sold_at_from
    } = filter.filters

    const campaignId = getCampaignId()

    const response = await api.put<{ data?: CampaignsResponseData }>(
      `campaigns/${campaignId}`,
      { 
        campaign: {
          lot_filters: {
            min_estimated_price, max_estimated_price, min_acres, max_acres,
            ...advancedFilter.getAdvancedFilter(), county_id,
            excluded_apns: lotScrub.getExcludedMarkers()
          },
          comp_filters: {
            min_price: min_estimated_price, max_price: max_estimated_price, 
            min_acres, max_acres, sold_at_from,
            status: filter.getCompStatus()
          },
          polygon: selectedPolygon.value
        }
      } 
    )

    //Used for auto-save campaign
    isSavingCampaign.value = false 

    if (response.status == RESPONSE.SUCCESS) {
      lastSavedTimeStamp.value = new Date()
      campaign.selectedCampaign = response.data.data!
      sessionStorage.setItem('campaign', JSON.stringify(response.data.data))
      return true
    }
  } 

  return {
    isSavingCampaign,
    lastSavedTimeStamp,
    preventInitialFetch,
    resetAutoSave,
    saveCampaign,
    skipSaveCampaign
  }
})