import { ref } from 'vue'
import type { PricingPlanContent } from '@/types/pricingPlan'

export function usePricingPlan () {
  const pricingPlans = ref<PricingPlanContent[]>([
    {
      type: 'Basic',
      description: `Get started with all the tools needed
      to acquire land.`,
      price: 99,
      features: [
        `Up to 10k/mo records searched and priced`,
        `Access to LotHunt advanced reports`,
        `Only $0.20 per 1 sheet double windowed mail*`,
        `Only $0.08 per data record`,
        `Template Editing`,
        `Advanced Filters`,
      ],
    },
    {
      type: 'Professional',
      description: `For when you get serious on your land journey`,
      price: 249,
      features: [
        `All features in Basic`,
        `Up to 100k/mo records searched and priced`,
        `Only $0.18 per 1 sheet double windowed mail*`,
        `Only $0.06 per data record`,
      ],
    },
    {
      type: 'Business',
      description: `Dominate the market with the lowest cost per mail`,
      price: 599,
      features: [
        `All features in Professional`,
        `Up to 500k/mo records searched and priced`,
        `Only $0.16 per 1 sheet double windowed mail*`,
        `Only $0.05 per data record`,
      ],
    },
    {
      type: 'Enterprise',
      description: `Speak to us anyime to discuss volume discounts. 
      Starts at:`,
      price: 2000,
      features: [
        `All features in Business`,
        `Special high volume discounts`,
        `Priority support`,
      ],
    },
  ]);
  
  const getEnterprisePlan = 
    pricingPlans.value.filter(plan =>
      plan.type === 'Enterprise')?.map(enterprise => ({
        id: '',
        attributes: {
          name: enterprise.type,
          price: enterprise.price,
          interval: 'monthly'
        },
        ...enterprise,
        type: 'plan'
      }))?.[0]
  
  return {
    pricingPlans,
    getEnterprisePlan
  }
}