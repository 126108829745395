import { ROUTE } from '@/types/enums'
import type { RouteRecordRaw } from 'vue-router'
import ErrorPage from '@/views/ErrorPage.vue'
import AuthenticatedLayout from '@/layouts/AuthenticatedLayout.vue'
import { useCampaignStore } from '@/store/campaign/campaign'
import { useUserStore } from '@/store/user'
import { useAuthStore } from '@/store/authenticate'
import { parseStringToJson } from '@/utils/parseStringToJson'
import type { CampaignResponseData } from '@/types/api-response/campaign'
import loadMap from '@/modules/map/loadMap'

const checkStoredCampaign = () => {
  /* Delete campaign in sessionStorage if campaign type is upload. */ 
  const campaign = parseStringToJson<CampaignResponseData>(
    sessionStorage.getItem('campaign'))
   if (! campaign || campaign.attributes.campaign_type === 'upload') {
    sessionStorage.clear()
  }
}

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: ROUTE.HOME,
    redirect: {
      name: ROUTE.EXPLORE
    },
    beforeEnter: async(to, from, next) => {
      await loadMap()
      next()
    },
    meta: { requiresAuth: true },
    component: AuthenticatedLayout,
    children: [
      {
        path: '/explore/:campaignId?',
        name: ROUTE.EXPLORE,
        component: () => import('@/views/ExplorePage.vue'),
        beforeEnter: async (to, from, next) => {
          checkStoredCampaign();
          next()
        },
        meta: {
          title: 'Explore'
        }
      },
      {
        path: '/settings',
        name: ROUTE.SETTINGS,
        component: () => import('@/views/SettingsPage.vue'),
        redirect: {
          name: ROUTE.ACCOUNT_SETTINGS
        },
        meta: {
          title: 'Account Settings'
        },
        children: [
          {
            path: 'account',
            name: ROUTE.ACCOUNT_SETTINGS,
            component: () => import('@/views/settings/AccountSettings.vue'), 
          },
          {
            path: 'subscription',
            name: ROUTE.ACCOUNT_SUBSCRIPTION,
            component: () => 
                import('@/views/settings/AccountSubscription.vue'), 
          },
          {
            path: 'referrals',
            name: ROUTE.ACCOUNT_REFERRALS,
            component: () => 
                import('@/views/settings/AccountReferrals.vue'), 
          },
        ]
      },
      {
        path: '/campaigns',
        name: ROUTE.CAMPAIGNS,
        component: () => import('@/views/CampaignPage.vue'),
        meta: {
          title: 'Campaigns'
        }
      },
      {
        path: '/campaign/:id/activate/:step',
        name: ROUTE.ACTIVATE_CAMPAIGN,
        beforeEnter: async (to) => {
          const store = useCampaignStore()
          const campaign = await store.getCampaign(
            to.params?.id as string,
            true
          )

          if (campaign?.attributes?.status !== 'pending') {
            window.location.href = window.location.origin + '/explore'
          }
        },
        component: () => import('@/views/ActivateCampaign.vue'),
        meta: {
          title: 'Activate Campaign'
        }
      },
      {
        path: '/lothunt-report',
        name: ROUTE.LOTHUNT_REPORT,
        component: () => import('@/views/LotHuntReport.vue'),
        meta: {
          title: 'LotHunt Report'
        }
      },
    ]
  },
  {
    path: '/register',
    name: ROUTE.REGISTER,
    component: () => import('@/views/auth/SignupPage.vue'),
    meta: {
      requiresAuth: false,
      title: 'Create Account'
    }
  },
  {
    path: '/plans',
    name: ROUTE.SUBSCRIBE,
    component: () => import('@/views/SelectSubscription.vue'),
    beforeEnter: async (to, from, next) => {
      const store = useUserStore()
      if (store.user?.attributes.account_role === 'member') {
        return next({ name: ROUTE.SETTINGS })
      } else {
        next()
      }
    },
    meta: {
      title: 'Subscribe Plan',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: ROUTE.LOGIN,
    component: () => import('@/views/auth/LoginPage.vue'),
    meta: {
      requiresAuth: false,
      title: 'Login Account'
    }
  },
  {
    path: '/reset-password',
    name: ROUTE.FORGOT_PASSWORD,
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      title: 'Reset Password',
      requiresAuth: false
    }
  },
  {
    path: '/terms-of-use',
    name: ROUTE.TERMS_OF_USE,
    component: () => import('@/views/TermsOfUse.vue'),
    beforeEnter: async (to, from, next) => {
      const store = useUserStore()
      const { getAuthUser } = useAuthStore()

      await getAuthUser()
      if (store.user?.attributes.terms_accepted_at) {
        return next({ name: ROUTE.EXPLORE })
      } else {
        next()
      }
    },
    meta: {
      requiresAuth: true,
      title: 'Terms of Use'
    }
  },
  
  {
    path: '/:pathMatch(.*)*',
    name: ROUTE.NOT_FOUND,
    component: ErrorPage,
    meta: {
      title: 'Page Not Found'
    }
  },
]