import type { LotsResponseData } from '@/types/api-response/lots';
import type { MapMarker } from '@/types/mapMarkers';
import { format } from './numberFormat';

export const sortMarkerData = (
  markers: MapMarker[] | LotsResponseData[]
): MapMarker[] | LotsResponseData[] => {
  if (!markers || (Array.isArray(markers) && markers.length === 0)) {
    return [];
  }

  //eslint-disable-next-line complexity
  const initialSort = markers.slice().sort((a, b) => {
    if ((a.attributes?.lat ?? 0) !== (b.attributes?.lat ?? 0)) {
      return (b.attributes?.lat ?? 0) - (a.attributes?.lat ?? 0);
    }
    return (b.attributes?.lng ?? 0) - (a.attributes?.lng ?? 0);
  })
  
  const sortedByNearest = sortMarkersByNearestNeighbor(
    initialSort as MapMarker[])

  return sortedByNearest.map(marker => ({
    ...marker,
    formatted_price: format(marker!.attributes?.estimated_price!, true)
  })) as MapMarker[];
}

function calculateDistance(marker1: MapMarker, marker2: MapMarker) {
  return Math.hypot(
    marker1.attributes?.lat! - marker2.attributes?.lat!,
    marker1.attributes?.lng! - marker2.attributes?.lng!
  )
}

function sortMarkersByNearestNeighbor(markers: MapMarker[]) {
  const sortedMarkers = [markers.shift()]; // Start with the first marker
  
  while (markers.length > 0) {
    const lastMarker = sortedMarkers[sortedMarkers.length - 1];
    let nearestIndex = 0;
    let shortestDistance = Infinity;
    
    markers.forEach((marker, index) => {
      const distance = calculateDistance(lastMarker!, marker);
      if (distance < shortestDistance) {
        shortestDistance = distance;
        nearestIndex = index;
      }
    });
    
    sortedMarkers.push(markers.splice(nearestIndex, 1)[0]);
  }
  
  return sortedMarkers;
}