import type { 
  LotHuntScrubConstructor, 
  MarkerContainer, 
  MarkerType, 
  ScrubbingDetails
} from '@/types/map/lothunt-scrub';
import type { MapMarker } from '@/types/mapMarkers';

export class LotHuntScrub implements LotHuntScrubConstructor {
  private static _instance: LotHuntScrub | null = null;

  //Set for future use
  private _type: MarkerType = 'lot'
  //Marker holder for scrub
  private _markers: MarkerContainer[] = []
  //Current marker to return and get based on _type
  private _currentMarker: MapMarker[] = [];
  //Index for scrolling through markers
  private _currentIndex: number = 0;
  //Excluded markers
  private _excludedMarkers: string[] = []

  private _enabbleScrubbing: boolean = true

  /**
   * Reference for the current shown marker
   * to hide/remove it from the map. (LotHunt Marker)
   */
  private _shownMarker: MapMarker | null = null

  constructor() {
    if (LotHuntScrub._instance) {
      return LotHuntScrub._instance
    }
    LotHuntScrub._instance = this;
  }

  getFirstMarker(): MapMarker {
    this._currentIndex = 0
    this._shownMarker = this._currentMarker[this._currentIndex] 
    return this._currentMarker[this._currentIndex]
  }

  getPreviousMarker(): MapMarker {
    this._currentIndex--
    this._shownMarker = this._currentMarker[this._currentIndex] 
    return this._currentMarker[this._currentIndex]
  }

  getNextMarker(): MapMarker {
    if (this._currentIndex + 1 < this._currentMarker.length) {
      this._currentIndex += 1
    }
    this._shownMarker = this._currentMarker[this._currentIndex] 
    return this._currentMarker[this._currentIndex]
  }

  getLastMarker(): MapMarker {
    this._currentIndex = this._currentMarker.length - 1
    this._shownMarker = this._currentMarker[this._currentIndex] 
    return this._currentMarker[this._currentMarker.length - 1]
  }

  getIndexMarker(index: number): MapMarker {
    this._currentIndex = index 
    this._shownMarker = this._currentMarker[this._currentIndex] 
    return this._currentMarker[index]
  }

  getMarkerBasedOnType(): void {
    this._currentMarker = this._markers.find(marker => 
        marker.type === this._type)?.markers ?? []
  }

  getMarkerById(id: string): { marker: MapMarker, index: number } | null {
    const index = this._currentMarker.findIndex(
      marker => marker.id === id)
    
    if (index !== -1) {
      this._currentIndex = index 
       
      return {
        marker: this._currentMarker[index],
        index: index
      }
    }

    return null
  }

  getCurrentMarker(): MapMarker {
    return this._currentMarker[this._currentIndex]
  }

  getScrubbingInfo(): ScrubbingDetails {
    return {
      _currentIndex: this._currentIndex,
      _totalMarkers: this._currentMarker.length
    }
  }

  /* Excluded Markers */
  excludeLot(apn: string): void {
    const index = this._excludedMarkers.indexOf(apn)
    if (index !== -1) {
      this._excludedMarkers.splice(index, 1) 
    } else {
      this._excludedMarkers.push(apn)
    }

    window.dispatchEvent(new Event('excluded-marker-updated'))
  }

  getExcludedMarkers(): string[] {
    return this._excludedMarkers
  }

  setExcludedMarkers(apns: string[]): void {
    this._excludedMarkers = apns
  }

  isExcluded(apn: string): boolean {
    return this._excludedMarkers.includes(apn) 
  }

  /**
   * Set the markers that will hold the data for scrubbing feature.
   * This will replace the marker if it has the same type.
   * @param markers MarkerContainer
   */
  setMarkers(markers: MarkerContainer): void {
    const index = this._markers.findIndex(marker => 
      marker.type === markers.type);

    // Replace the existing marker with the new one
    if (index !== -1) {
      this._markers[index] = markers;
    } else {
      this._markers.push(markers);
    }
    //Set marker for scrubbing
    this.getMarkerBasedOnType()
  }

  setEnableScrubbing(enabled: boolean): void {
    this._enabbleScrubbing = enabled
  }

  getEnableScrubbing(): boolean {
    return this._enabbleScrubbing
  }

  setShownMarker(marker: MapMarker | null): void {
    this._shownMarker = marker
  }

  getShownMarker(): MapMarker | null {
    return this._shownMarker
  }

  getMarkersLength(): number {
    return this._currentMarker.length
  }
}