<script setup lang="ts">
import { ref, onMounted, watch, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { useCampaignStore } from '@/store/campaign/campaign'
import { useCreateCampaign } from '@/store/csv-campaign'
import CModal from '../CModal.vue'
import CreateCampaign from './modal/CreateCampaign.vue';
import CStepper from '../CStepper.vue'
import CreateCampaignOptions from './modal/CreateCampaignOptions.vue'
import { useGoogleMap } from '@/composables/google-map/useGoogleMap'
import { useExploreStore } from '@/store/explore'
import { useSaveCampaign } from '@/store/campaign/save-campaign' 
import { useMapHelpers } from '@/composables/google-map/useMapHelpers'
import { useAdvancedFilters } from '@/composables/useAdvancedFilters'
import { ROUTE } from '@/types/enums';
import { useFilters } from '@/composables/useFilters'

const model = defineModel<boolean>()

const toast = useToast()
const router = useRouter()
const campaign = useSaveCampaign()
const campaignStore = useCampaignStore()
const createCampaign = useCreateCampaign()
const advancedFilter = useAdvancedFilters()
const { clearFilters } = useFilters()
const { getMapInstance } = useGoogleMap()
const { toggleDrawOnMap, selectedPolygon } = useMapHelpers()

const store = useExploreStore()

const currentStep = ref<number>(1)

const getComponent = () => ({
  1: CreateCampaign,
  2: CreateCampaignOptions
}[currentStep.value])

const nextStep = () => currentStep.value += 1

onMounted(() => {
  currentStep.value = 1
})

//Reset New Campaign
const resetFields = () => {
  createCampaign.campaignName = ''
  createCampaign.campaignType = null
}

const saveCampaign = async () => {
  const campaignId = await campaignStore.createCampaign(
    createCampaign.campaignName, createCampaign.campaignType!)
  toggleDrawOnMap(campaignStore.selectedCampaign?.attributes.status 
    === 'pending')
  //Reset Median Days on Market value
  store.mdnDaysOnMarket = null
  //Return if campaign type is upload and redirect to
  //activate campaign process
  campaign.resetAutoSave()
  campaign.skipSaveCampaign = false //Alow save at first drawing 

  if (createCampaign.campaignType === 'upload') {
    model.value = false
    resetFields()
    createCampaign.resetFields(true)
    return await router.replace({ 
      name: ROUTE.ACTIVATE_CAMPAIGN,
      params: { id: campaignId, step: 1 }
    })
  }
  
  if (campaignId) {
    resetFields()
    toast.success('Campaign has been created!')
    store.lots = [] 

    await router.replace({ 
      name: ROUTE.EXPLORE,
      params: { campaignId: campaignId }
    })
    
    store.clearPolygon()
    store.clearLotsAndComps()
    advancedFilter.clearFilters()
    clearFilters()
    selectedPolygon.value.length = 0
    centerAndZoomMap()
  }
  
  currentStep.value = 1
  model.value = false 
}

const centerAndZoomMap = () => {
  const map = getMapInstance()
  if (!map) return
  
  map.setZoom(5)
  map.setCenter({lat: 38.54580751879986, lng: -105.722244})
}

watch(
  () => model.value,
  () => {
    if (model.value) {
      currentStep.value = 1
      const activeEl = document.activeElement as HTMLElement
      if (activeEl && activeEl instanceof HTMLButtonElement) {
        activeEl.blur()
      }
      nextTick(() => {
        const inputEl = 
          document.getElementById('campaignName') as HTMLInputElement
        inputEl?.focus()
      })
    }
  }
)
</script>

<template>
  <CModal v-model="model" data-cy="newCampaignModal" no-padding>
    <div class="bg-gray-200 p-4 rounded-t-md">
      <CStepper
        class="rounded-t-md mx-auto"
        :current-step="currentStep"
        :steps="2"
      />
    </div>
    <div class="px-6 py-5">
      <component
        :is="getComponent()" 
        @next-step="() => nextStep()"
        @previous="currentStep = 1"
        @proceed="saveCampaign"
      />
    </div>
  </CModal>
</template>