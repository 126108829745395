<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { Icon } from '@iconify/vue';
import { debounce } from '@/utils/debounce';
import type inputProps from '@/types/components/inputProps';

const props = withDefaults(defineProps<inputProps>(), {
  type: 'text',
  required: false,
  placeholder: '',
  label: '',
  errorMessage: '',
  disabled: false,
  password: false,
  autofocus: false,
  size: 'base',
});

const emit = defineEmits<{
  (e: 'blur', event: FocusEvent): void;
  (e: 'keyup', event: KeyboardEvent): void;
}>();

const model = defineModel<string | number | null>();
const exceedMin = ref<boolean>(false);

const passwordShown = ref<boolean>(false);

onMounted(
  () => {
    if (! props.min) return 
  
    if (! model.value) {
      exceedMin.value = false 
      return 
    }
    exceedMin.value = +model.value < +props.min
  }
);

watch(
  () => model.value,
  debounce(() => {
    if (!model.value) {
      exceedMin.value = false;
      return;
    }
    if (!props.min) return;

    exceedMin.value = +model.value < +props.min;
  }, 800),
);

const validateInput = () => {
  const el = document.getElementById(props.id) 

  if (! model.value) {
    exceedMin.value = false 
    el?.removeAttribute('title')
    return 
  }

  if (! props.min) return 
  exceedMin.value = +model.value < +props.min
  
  if (el && exceedMin.value) {
    el.setAttribute(
      'title', 
      `Value must be greater than or equal to ${props.min}`
    )
  }
}

const validateInputNumber = (e: Event) => {
  if (props.type !== 'number') return
  
  if ((e as KeyboardEvent).key === 'e') {
    e.preventDefault()
  }
}

watch(() => model.value, debounce(() => validateInput(), 500))

defineExpose({ validateInput })
</script>

<template>
  <div :data-cy="dataCy && `${dataCy}_formInput`">
    <slot name="label">
      <label v-if="label" :for="id" class="text-sm font-normal">
        {{ label }}
        <span v-if="required" class="text-red-600 inline-block">*</span>
      </label>
    </slot>
    <div class="relative mt-0.5">
      <div
        v-if="$slots?.leading"
        class="absolute top-1/2 -translate-y-1/2"
        :class="size === 'sm' ? 'ml-1.5' : 'ml-3'"
      >
        <slot name="leading"></slot>
      </div>
      <input
        :id="id"
        :ref="inputRef"
        v-model="model"
        :type="!password ? type : passwordShown ? 'text' : 'password'"
        class="form-input"
        :class="[
          $slots.leading && size === 'sm'
            ? 'pl-6'
            : $slots.leading
              ? 'pl-9'
              : '',
          { 'pr-9': $slots.trailing },
          { 'form-input--invalid': errorMessage || exceedMin },
          { 'form-input--sm': size === 'sm' },
          inputClass
        ]"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :autofocus="autofocus"
        :min="`${min as number}`"
        :max="`${max as number}`"
        :data-cy="dataCy && `${dataCy}_formInput_input`"
        :autocomplete="autocomplete"
        @keydown="validateInputNumber"
        @blur="e => emit('blur', e)"
        @keyup="e => emit('keyup', e)"
      />
      <div
        v-if="$slots?.trailing || password"
        class="absolute top-1/2 -translate-y-1/2 right-4"
        :data-cy="dataCy && `${dataCy}_formInput_passwordShown`"
      >
        <slot name="trailing">
          <Icon
            v-if="password"
            :icon="
              !passwordShown
                ? 'ic:baseline-visibility'
                : 'ic:baseline-visibility-off'
            "
            class="cursor-pointer"
            @click="passwordShown = !passwordShown"
          />
        </slot>
      </div>
    </div>
    <span 
      :data-cy="dataCy && `${dataCy}_formInput_inputError`"
      class="text-xs text-red-500"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<style>

.form-input {
  @apply !bg-white !border-gray-300 !py-2.5 !text-sm 
text-gray-900 rounded-lg focus:!ring-primary-200
focus:!border-primary-200/20 block w-full !outline-none 
  !border !transition-all !appearance-none !duration-150 
disabled:!bg-gray-100 ease-in-out;
}

.form-input--invalid {
  @apply focus:!border-red-600 focus:!ring-transparent
!border-red-600;
}

.form-input--sm {
  @apply text-xs py-2;
}
</style>