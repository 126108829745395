import { computed, ref, toRaw } from 'vue'
import type { CountyDropdown, County, Shape } from '@/types/counties'
import api from '@/lib/axios'
import { usePricingPlan } from './usePricingPlan'
import { RESPONSE } from '@/types/enums'
import type { PricingPlan, PricingPlanData } from '@/types/pricingPlan'
import type { 
  LandUseCodeGroup, 
  LandUseGroupItem 
} from '@/types/api-response/settings'

export function useSettings() {
  const { pricingPlans, getEnterprisePlan } = usePricingPlan()
  
  const settingsData = ref(localStorage.getItem('settings'))

  const counties = computed<CountyDropdown[]>(
    () => {
      if (settingsData.value) {
        return (JSON.parse(settingsData.value)).counties.data.map(
          (county: County) => ({ 
            id: county.id,
            label: `${county.attributes.full_name}, ${county.attributes.state}`,
            name: county.attributes.name,
            state_name: county.attributes.state_name,
            value: {
              lat: county.attributes.lat,
              lng: county.attributes.lng,
            }
          })
        )
      }
      
      return []
    }
  )

  const plans = () => {
    if (settingsData.value) {
      return JSON.parse(settingsData.value).plans
    } else {
      return JSON.parse(localStorage.getItem('settings') || '{}').plans
    }
  }

  const monthlyPlans = computed<PricingPlanData[]>(
    () => {
      return [...plans().data.filter((plan: PricingPlan) => 
      plan.attributes?.interval === 'monthly').map(
        (plan: PricingPlan) => ({
          ...plan, 
          ...pricingPlans.value.filter(pricingPlan => 
            pricingPlan.type === plan.attributes?.name)
            .map(p => ({
              features: p.features,
              description: p.description
            }))?.[0]
        })
      ), getEnterprisePlan as PricingPlanData]}
  )

  const yearlyPlans = computed<PricingPlanData[]>(
    () => [...plans().data.filter((plan: PricingPlan) => 
      plan.attributes.interval === 'yearly').map(
        (plan: PricingPlan)  => ({
          ...plan, 
          monthly_price: +monthlyPlans.value.filter(
            monthlyPlan => plan.attributes.name === monthlyPlan.attributes.name 
          )?.[0].attributes.price,
          ...pricingPlans.value.filter(pricingPlan => 
            pricingPlan.type === plan.attributes.name)
            .map(p => ({
              features: p.features,
              description: p.description
            }))?.[0]
        })
      ), getEnterprisePlan as PricingPlanData]
  )

  const getCountyById = (id: string) => {
    return counties.value.find(county => county.id === id)
  }

  const countyWithBoundary = ref<County | null>(null)
  
  const getCountyBoundaries = async (id: string):
    Promise<Shape | undefined> => {
    const response = await api.get<{ data: County }>(`counties/${id}`)

    if (response.status === RESPONSE.SUCCESS) {
      countyWithBoundary.value = response.data.data
      return countyWithBoundary.value.attributes.shape
    }
  }

  const stripePublicKey = computed<string>(
    () => {
      if (settingsData.value) {
        return JSON.parse(settingsData.value).stripe_public_key
      }

      return ''
    }
  )

  const landUseCodes = computed(
    () =>  {
      if (settingsData.value) {
        const landUseCodes = (JSON.parse(settingsData.value)).land_use_codes 
        
        //eslint-disable-next-line complexity
        const codes = landUseCodes.reduce((
          group: LandUseCodeGroup[], item: LandUseGroupItem) => {
            const groupedCodes = group?.findIndex(group => 
                group.category === item.group)
            if (groupedCodes === -1) {
              group.push({ category: item.group, items: [toRaw(item)]})
            } else {
              group[groupedCodes].items.push(toRaw(item))
            }
            return group
          },[])
        return toRaw(codes)
      }

      return []
    }
  )

  const slopes = computed(
    () =>  {
      if (settingsData.value) {
        return (JSON.parse(settingsData.value)).slopes?.map(
          (slope: any) => ({
            label: slope.description,
            value: slope.code   
          })
        )
      }

      return []
    }
  )

  return {
    counties,
    countyWithBoundary,
    getCountyById,
    getCountyBoundaries,
    landUseCodes,
    slopes,
    monthlyPlans,
    plans,
    settingsData,
    stripePublicKey,
    yearlyPlans,
  }
}